import React from 'react'
import PropTypes from 'prop-types'
import YesNoUnknownCheckbox from './YesNoUnknownCheckbox'
import OfferingCheckbox from './OfferingCheckbox'
import { TooltipAnchor, Tooltip } from './Tooltip.jsx'

import './OfferingsRow.scss'

const OfferingsRow = ({
  branchData,
  inputNamePrefix,
  disabledTooltipText,
  knownOfferings, // {'abortion_pill': true, ...}
  getExtraStylesForRow,
  applyKnownOfferings,
  isRoot,
  disabled,
  disabledKeys,
  yesNoUnknown,
}) => {
  // null | true | false
  const currentValue =
    knownOfferings[branchData.key] === undefined
      ? null
      : knownOfferings[branchData.key]

  const extraStyles = getExtraStylesForRow(branchData.key)

  function getChildrenKeys(forBranchData, childrenKeys = []) {
    childrenKeys.push(forBranchData.key)
    forBranchData.children.forEach((child) =>
      getChildrenKeys(child, childrenKeys)
    )
    return childrenKeys
  }

  // Handle the change at the level of the child that was actually clicked;
  // create a tiny knownOfferings object with JUST the key and its new value.
  const handleYesNoUnknownCheckboxChange = (_event, offeringValue) => {
    const knownOfferingsChanges = { [branchData.key]: offeringValue }

    // set all children to false
    if (offeringValue === false) {
      // recursively collect the children keys
      const childrenKeys = getChildrenKeys(branchData)
      // add all of them to this knownOfferingsChanges with them set to false
      for (const childKey of childrenKeys) {
        knownOfferingsChanges[childKey] = false
      }
    }

    // Call the parent applyKnownOfferings; either the root the parentApplyKnownOfferings of our parent.
    applyKnownOfferings(knownOfferingsChanges)
  }

  // If a child of this OfferingsRow is click, it will call this on its way
  // up to the root. This appends any changes we need to make along the way
  // to the tiny knownOfferings object with accumulated changes.
  const parentApplyKnownOfferings = (knownOfferingsChanges) => {
    // what did we change the child to be?
    const change = Object.values(knownOfferingsChanges)[0]
    // if the child is being set to true, set this parent to also be true
    let updatedKnownOfferingsChanges = knownOfferingsChanges

    if (change === true) {
      updatedKnownOfferingsChanges = {
        ...knownOfferingsChanges,
        [branchData.key]: true,
      }
    }
    // Call the parent applyKnownOfferings; either the root the parentApplyKnownOfferings of our parent.
    applyKnownOfferings(updatedKnownOfferingsChanges)
  }

  let output = yesNoUnknown ? (
    <div className="checkbox-row" data-testid={branchData.key}>
      <YesNoUnknownCheckbox
        name={`${inputNamePrefix}[${branchData.key}]`}
        sectionKey={branchData.key}
        value={currentValue}
        disabled={disabled || disabledKeys.includes(branchData.key)}
        onChange={handleYesNoUnknownCheckboxChange}
        visualStyle={`pill ${extraStyles.checkbox}`}
      />
      <div
        className={`title ${branchData.priority ? 'priority' : ''} ${
          extraStyles.label
        }`}
      >
        {branchData.label}
      </div>
    </div>
  ) : (
    <OfferingCheckbox
      name={`${inputNamePrefix}[${branchData.key}]`}
      checked={currentValue}
      onChange={(e) => handleYesNoUnknownCheckboxChange(e, e.target.checked)}
      label={branchData.label}
    />
  )

  if (
    (disabled || disabledKeys.includes(branchData.key)) &&
    disabledTooltipText
  )
    output = (
      <TooltipAnchor placement="right">
        {output}
        <Tooltip>
          You cannot change the three top-level abortion services from this
          view. Please edit those at the clinic level.
        </Tooltip>
      </TooltipAnchor>
    )

  const childrenCountClass = branchData.children.length
    ? `child-count-${branchData.children.length}`
    : ''

  return (
    <div
      className={`OfferingsRow ${isRoot ? 'root' : ''} ${childrenCountClass}`}
    >
      {output}

      {branchData.children.map((childBranchData) => (
        <OfferingsRow
          applyKnownOfferings={parentApplyKnownOfferings}
          branchData={childBranchData}
          disabled={disabled}
          disabledKeys={disabledKeys}
          disabledTooltipText={disabledTooltipText}
          getExtraStylesForRow={getExtraStylesForRow}
          inputNamePrefix={inputNamePrefix}
          key={childBranchData.key}
          knownOfferings={knownOfferings}
          yesNoUnknown={yesNoUnknown}
        />
      ))}
    </div>
  )
}

OfferingsRow.defaultProps = {
  disabled: false,
  disabledKeys: [],
  disabledTooltipText: '',
  getExtraStylesForRow: (_key) => ({ checkbox: '', label: '' }),
  isRoot: false,
  yesNoUnknown: false,
}

const branchDataShape = {
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  priority: PropTypes.bool.isRequired,
}

OfferingsRow.propTypes = {
  branchData: PropTypes.shape({
    ...branchDataShape,
    children: PropTypes.arrayOf(PropTypes.shape(branchDataShape)),
  }).isRequired,
  inputNamePrefix: PropTypes.string.isRequired,
  disabledTooltipText: PropTypes.string,
  knownOfferings: PropTypes.objectOf(PropTypes.bool).isRequired,
  getExtraStylesForRow: PropTypes.func,
  applyKnownOfferings: PropTypes.func.isRequired,
  isRoot: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledKeys: PropTypes.arrayOf(PropTypes.string),
  yesNoUnknown: PropTypes.bool,
}

export default OfferingsRow
