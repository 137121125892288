import React from 'react'
import PropTypes from 'prop-types'
import blueXIcon from '../../../assets/images/blueX.svg'
import './CurrentFilters.scss'

const CurrentFilters = ({ currentFilters, removeFilter, removeAllFilters }) => {
  if (!currentFilters || Object.keys(currentFilters).length === 0) return null

  return (
    <div id="CurrentFilters">
      <div className="label">Filters applied:</div>
      <ul>
        {currentFilters.map(({ key, label, displayValue }) => (
          <li key={key}>
            {`${label}: ${displayValue}`}
            <button
              className="close-filter"
              type="button"
              onClick={() => removeFilter(key)}
            >
              <img src={blueXIcon} alt="Remove Filter" />
            </button>
          </li>
        ))}
      </ul>
      <div className="spacer" />
      <button
        className="clear_filters_btn"
        type="button"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          removeAllFilters()
        }}
      >
        Clear Filters
      </button>
    </div>
  )
}

CurrentFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      displayValue: PropTypes.string,
    })
  ).isRequired,
  removeFilter: PropTypes.func.isRequired,
  removeAllFilters: PropTypes.func.isRequired,
}

export default CurrentFilters
