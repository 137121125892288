// Relevant related files:
//

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { OrgSelectInput } from '../navigator_support_orgs/inputs/OrgInputs.jsx'
import usStates from '../../utilities/us_states.js'
import GenericNavigatorFacingIndex from '../../shared/index_pages/GenericNavigatorFacingIndex.jsx'
import './ProfileIndex.scss'

const ProfileIndex = ({
  all_profiles,
  initial_filters,
  initial_order,
  initial_page,
  user_orgs,
}) => {
  const isUsStateDisabled = useMemo(() => {
    const enabledUsStates = new Set(
      Object.values(all_profiles).flatMap((c) => c.geographic_coverage_areas)
    )
    return (state_abbrev) => !enabledUsStates.has(state_abbrev)
  }, [all_profiles])

  const topFilters = [
    {
      label: 'Filter by State',
      key: 'serving_states',
      InputComponent: OrgSelectInput,
      extraProps: {
        options: Object.entries(usStates).map((x) => x.reverse()),
        isValueDisabled: isUsStateDisabled,
      },
      defaultValue: 'National',
    },
    {
      label: 'Filter by Organization',
      key: 'user_org_id_eq_any',
      InputComponent: OrgSelectInput,
      extraProps: {
        options: Object.entries(user_orgs).map((x) => x.reverse()),
      },
      defaultValue: 'Any Organization',
    },
  ]

  // add "Any Organization" to the list of organizations
  topFilters[1].extraProps.options.unshift([
    'Any Organization',
    'Any Organization',
  ])

  const orderItems = [
    {
      directions: ['asc', 'desc'],
      key: 'full_name',
      labels: {
        asc: { long: 'Name: A-Z', short: 'Name: A-Z' },
        desc: { long: 'Name: Z-A', short: 'Name: Z-A' },
      },
      sortVal: (id) => all_profiles[id].full_name || '',
    },
    {
      directions: ['asc', 'desc'],
      key: 'title',
      labels: {
        asc: { long: 'Title: A-Z', short: 'Title: A-Z' },
        desc: { long: 'Title: Z-A', short: 'Title: Z-A' },
      },
      sortVal: (id) => all_profiles[id].title || '',
    },
  ]

  const recordOptionsForLinkSharingForm = useMemo(
    () => Object.values(all_profiles).map((org) => [org.name, org.id]),
    [all_profiles]
  )

  const tableColumns = [
    {
      header: 'Name',
      cssClass: 'col-name',
      render: (profile) => (
        <a href={`/profiles/${profile.id}`}>{profile.full_name}</a>
      ),
      testId: 'col-name',
    },
    {
      header: 'Email',
      cssClass: 'col-email',
      render: (profile) =>
        profile.email ? (
          <a href={`mailto:${profile.email}`}>{profile.email}</a>
        ) : (
          '-'
        ),
    },
    {
      header: 'Title',
      cssClass: 'col-title',
      render: (profile) => profile.title,
    },
    {
      header: 'Organization',
      cssClass: 'col-organization',
      render: (profile) => user_orgs[profile.user_org_id],
    },
    {
      header: 'Geographic Coverage Areas',
      cssClass: 'col-geo',
      render: (profile) =>
        profile.geographic_coverage_areas
          .map((x) => usStates[x])
          .sort()
          .join(', '),
    },
    {
      header: 'Point of Contact',
      cssClass: 'col-point_of_contact',
      render: (profile) => (profile.org_point_of_contact ? 'Yes' : 'No'),
    },
  ]

  return (
    <div id="ProfileIndex">
      <GenericNavigatorFacingIndex
        filter_drawer_options={{}}
        filterDrawers={null}
        hashOfRecords={all_profiles}
        hasSelectableColumn={false}
        initial_filters={initial_filters}
        initial_order={initial_order}
        initial_page={initial_page}
        orderItems={orderItems}
        pageTitle="User Profiles"
        recordOptionsForLinkSharing={recordOptionsForLinkSharingForm}
        recordType="Profile"
        tableColumns={tableColumns}
        topFilters={topFilters}
      />
    </div>
  )
}

export const ProfileType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  geographic_coverage_areas: PropTypes.arrayOf(PropTypes.string).isRequired,
})

ProfileIndex.propTypes = {
  all_profiles: PropTypes.objectOf(ProfileType).isRequired, // eslint-disable-line react/forbid-prop-types
  initial_filters: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  initial_order: PropTypes.string.isRequired,
  initial_page: PropTypes.number.isRequired,
}

export default ProfileIndex
