import React, { useEffect, useState, useReducer } from 'react'
import { humanizeString } from '../../utilities/humanize_string.js'
import { getCsrfToken } from '../../utilities/utilities.js'
import { mergeAndClean } from '../../utilities/compare_and_merge.js'
import LoadinDots from '../../shared/LoadinDots.jsx'
import CancelModal from '../navigator_support_orgs/modals/CancelModal.jsx'
import './BulkEditMain.scss'

const BulkEditMainSection = ({
  edits,
  inputs,
  recordDetails,
  clinicOfferingsTree,
  sectionLabel,
  setFormErrors,
  updateEdits,
}) => {
  if (inputs.length === 0) return ''
  return (
    <div className="BulkEditMainSection">
      <hr className="non-abortion-services-hrs" />
      <div
        className={`section-label ${
          sectionLabel === 'Abortion Services' ? 'hidden' : ''
        }`}
      >
        {sectionLabel === 'Abortion Services' ? '' : sectionLabel}
      </div>
      {inputs.map(([bulkEditKey, { InputComponent, extraProps }]) => (
        <InputComponent
          edits={edits}
          isClearable={true}
          key={bulkEditKey}
          label={humanizeString(bulkEditKey)}
          onChange={(v) => updateEdits({ [bulkEditKey]: v })}
          recordDetails={recordDetails}
          clinicOfferingsTree={clinicOfferingsTree}
          setErrors={(bool) => setFormErrors({ [bulkEditKey]: bool })}
          updateEdits={updateEdits}
          value={edits[bulkEditKey] || ''}
          {...extraProps}
        />
      ))}
    </div>
  )
}

const SidebarBrick = ({ label, clickX }) => (
  <div className="SidebarBrick">
    <button type="button" onClick={clickX}>
      ✕
    </button>
    <span>{label}</span>
  </div>
)

const BulkEditMain = ({
  clickBackButton,
  clickCancelButton,
  clinicNames,
  editableAttributeGroups,
  recordDetails,
  clinicOfferingsTree,
  selectedRecordIds,
  selectedAttributes,
  setSelectedRecordIds,
  successPath,
  // setSelectedAttribute,
}) => {
  const [edits, updateEdits] = useReducer(mergeAndClean, {})
  const [submittingForm, setSubmittingForm] = useState(false)
  const [failedToUpdate, setFailedToUpdate] = useState({})
  const [updatedIds, setUpdatedIds] = useState([])
  const [remainingIds, setRemainingIds] = useState([])
  const [apiError, setApiError] = useState('')
  const [missingAttributesModalIsOpen, setMissingAttributesModalIsOpen] =
    useState(false)
  const [formErrors, setFormErrors] = useReducer(mergeAndClean, {})

  useEffect(() => {
    if (!submittingForm) return

    const fetchData = async () => {
      setApiError(null)
      try {
        const response = await fetch('/authenticated_api/bulk_edit', {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': getCsrfToken(),
          },
          body: JSON.stringify({ ids: selectedRecordIds, attributes: edits }),
        })

        if (response.ok) {
          const data = await response.json()
          if (selectedRecordIds.length === data.updated.length)
            window.location.href = `${successPath}&flash_notice=Bulk Edit Updated ${selectedRecordIds.length} clinics`
          else {
            setSelectedRecordIds([
              ...data.remaining,
              ...Object.keys(data.failed),
            ])
            setRemainingIds(data.remaining)
            setFailedToUpdate(data.failed)
            setUpdatedIds(data.updated)
            setSubmittingForm(false)
          }
        } else {
          const errorMessage = `Error: ${response.status} ${response.statusText}`
          throw new Error(errorMessage)
        }
      } catch (error) {
        setApiError(error.message)
        setSubmittingForm(false)
      }
    }

    fetchData()
  }, [submittingForm])

  if (submittingForm)
    return (
      <div id="BulkEditMain" className="loading">
        <h2>Processing Records. This could take up to 30 seconds.</h2>
        <LoadinDots />
      </div>
    )

  const missingAttributes = []
  for (const group of editableAttributeGroups) {
    for (const [bulkEditKey, { paramKeys }] of Object.entries(group.items)) {
      if (
        selectedAttributes.includes(bulkEditKey) &&
        !paramKeys.some((paramKey) => edits[paramKey])
      )
        missingAttributes.push(bulkEditKey)
    }
  }

  const missingAttributesModal = (
    <CancelModal
      header="Missing Attributes"
      body={`Nothing has been entered for attribute(s): ${missingAttributes
        .map((x) => humanizeString(x))
        .join(', ')}. These input(s) will be ignored.`}
      cancelButtonText="Keep Editing"
      confirmButtonText="Submit anyway"
      isOpen={missingAttributesModalIsOpen}
      closeModal={() => setMissingAttributesModalIsOpen(false)}
      confirmModal={() => {
        setMissingAttributesModalIsOpen(false)
        setSubmittingForm(true)
      }}
    />
  )

  const tryToSubmit = () => {
    if (missingAttributes.length > 0) setMissingAttributesModalIsOpen(true)
    else setSubmittingForm(true)
  }

  const editsPresent = !!Object.keys(edits).length

  const backButton = (
    <div className="backButton">
      <button type="button" className="back" onClick={clickBackButton}>
        Back
      </button>
    </div>
  )

  const buttonBar = (
    <div className="buttonBar">
      <div>
        <button type="button" className="cancel" onClick={clickCancelButton}>
          Cancel
        </button>
        <button
          type="button"
          className="finish"
          onClick={tryToSubmit}
          disabled={!editsPresent || Object.values(formErrors).some((x) => x)}
        >
          Finish
        </button>
      </div>
    </div>
  )

  const flashTypeMessages = Object.fromEntries(
    Object.entries({
      apiError,
      updated: updatedIds.length > 0 && `Updated ${updatedIds.length} clinics`,
      remaining:
        remainingIds.length > 0 &&
        `Request timed out. ${remainingIds.length} clinics remaining`,
      failedToUpdate: Object.keys(failedToUpdate).length > 0 && (
        <>
          <h4>Failed to update clinics:</h4>
          <ul className="failed-to-update">
            {Object.entries(failedToUpdate).map(([id, errorMessage]) => (
              <li key={id}>
                <span className="name">
                  {clinicNames[id] || `clinic ${id}`}
                </span>
                <span className="error">{errorMessage}</span>
              </li>
            ))}
          </ul>
        </>
      ),
    }).filter(([_k, v]) => v)
  )

  const warningType = {
    apiError: 'error',
    updated: 'notice',
    remaining: 'warning',
    failedToUpdate: 'warning',
  }

  return (
    <div className="BulkEditMain">
      {missingAttributesModal}
      <div className="main">
        <div className="flash-type-messages flashes">
          {Object.entries(flashTypeMessages).map(([key, val]) => (
            <div key={key} className={`flash flash_${warningType[key]}`}>
              {val}
            </div>
          ))}
        </div>
        <div className="main-header">
          {backButton}
          <div className="bulkedit-title-and-button-bar">
            <div className="main-header-title">Apply Bulk Edit</div>
            {buttonBar}
          </div>
        </div>
        <div className="main-content">
          {editableAttributeGroups.map(({ label, items }) => (
            <BulkEditMainSection
              edits={edits}
              inputs={Object.entries(items).filter(([bulkEditKey]) =>
                selectedAttributes.includes(bulkEditKey)
              )}
              key={label}
              recordDetails={recordDetails}
              clinicOfferingsTree={clinicOfferingsTree}
              sectionLabel={label}
              setFormErrors={setFormErrors}
              updateEdits={updateEdits}
            />
          ))}
        </div>
        <div className="main-footer">{buttonBar}</div>
      </div>
      <div className="main-sidebar">
        <div className="main-sidebar-title">Clinics</div>

        {selectedRecordIds.map((id) => (
          <SidebarBrick
            label={clinicNames[id] || id}
            clickX={() =>
              setSelectedRecordIds(
                selectedRecordIds.filter((elem) => elem !== id)
              )
            }
            key={id}
          />
        ))}
      </div>
    </div>
  )
}

export default BulkEditMain
