import { IndexState } from '../../shared/index_pages/IndexState.js'

export class NavigatorClinicIndexState extends IndexState {
  constructor({ isMapView, clickedId, ...rest }) {
    super(rest)
    this.isMapView = isMapView
    this.clickedId = clickedId
  }

  static setMapView(isShown) {
    return (oldState) => {
      const newState = oldState._clone({
        isMapView: isShown,
      })
      newState._pushUrlHistory()
      return newState
    }
  }

  static setClickedId(clinicId) {
    return (oldState) => {
      const newState = oldState._clone({
        clickedId: clinicId,
      })
      newState._pushUrlHistory()
      return newState
    }
  }

  //
  //    Private methods
  //

  // modifies NavigatorClinicIndexState in-place
  _adjustFromFilterServerData(oldState, serverData) {
    this.currentOrder = this.constructor.adjustOrder(
      this,
      this.filterQueryParams,
      serverData
    )
  }

  static adjustOrder(indexState, filterQueryParams, responseData) {
    let newOrder = indexState.currentOrder

    // when filtering by location:
    if (responseData.location_query_lng_lat) {
      newOrder ||= 'distance_asc'

      // when not filtering by location, don't allow distance or drive-time order
    } else if (['distance_asc', 'drive_time_asc'].includes(newOrder)) {
      newOrder = ''

      // when filtering by mapped_area:
    } else if (filterQueryParams.mapped_area) {
      newOrder ||= 'nearest_airport_asc'
    }
    return newOrder
  }

  _historyParams() {
    const params = super._historyParams()
    if (this.isMapView) params.mapview = 'true'
    if (this.clickedId) params.clicked_id = this.clickedId
    return params
  }
}
