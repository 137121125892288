import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import usStates from '../../utilities/us_states.js'
import { OrgSelectInput, OrgArrayCheckboxInput } from './inputs/OrgInputs.jsx'
import { RuleToFrom } from './RuleToFrom.jsx'
import GenericNavigatorFacingIndex from '../../shared/index_pages/GenericNavigatorFacingIndex.jsx'
import './NavigatorSupportOrgIndex.scss'

const usStateOptions = Object.entries(usStates)
  .slice(1) // remove "National"
  .map((x) => x.reverse())

const ColStatus = ({ org }) => (
  <>
    <div className={`status-pill ${org.display_status.toLowerCase()}`}>
      {org.display_status}
    </div>
    {/^paused/i.test(org.display_status) && (
      <>
        {org.paused_services_resume_on && (
          <div>
            until {new Date(org.paused_services_resume_on).toLocaleDateString()}
          </div>
        )}
        {org.website_paused_note_en && <div>{org.website_paused_note_en}</div>}
      </>
    )}
  </>
)

const ColEligibility = ({ org, bannedStates }) => (
  <>
    {org.rules.map((rule) => (
      <RuleToFrom
        bannedStates={bannedStates}
        rule={{ ...rule, supportOrgId: org.id }}
        key={rule.id}
      />
    ))}
  </>
)

export const navigatorSupportOrgTopFilters = [
  {
    label: 'From State',
    key: 'from_state_filter',
    InputComponent: OrgSelectInput,
    extraProps: { options: usStateOptions },
  },
  {
    label: 'To States (Optional)',
    key: 'to_state_filter',
    InputComponent: OrgSelectInput,
    extraProps: { options: usStateOptions, isMulti: true },
  },
]

const NavigatorSupportOrgIndex = ({
  all_orgs,
  authenticated_api_send_to_phone_path,
  banned_states,
  filter_drawer_options,
  initial_filters,
  initial_page,
  initial_order,
  on_starred_page = false,
}) => {
  // NOTE: filter drawers should now use the predicates (keys):
  // { name_eq_any: ['array', 'of', 'strings'], with_any_offerings: ['array', 'of', 'offering_keys'] }

  const bannedStates = new Set(banned_states)

  const orderItems = [
    {
      directions: ['asc', 'desc'],
      key: 'name',
      labels: {
        asc: { long: 'Name: A-Z', short: 'Name: A-Z' },
        desc: { long: 'Name: Z-A', short: 'Name: Z-A' },
      },
      sortVal: (id) => all_orgs[id].name,
    },
    {
      directions: ['asc', 'desc'],
      key: 'org_type',
      labels: {
        asc: { long: 'Type: A-Z', short: 'Type: A-Z' },
        desc: { long: 'Type: Z-A', short: 'Type: Z-A' },
      },
      sortVal: (id) => all_orgs[id].org_type,
    },
    {
      directions: ['asc', 'desc'],
      key: 'status',
      labels: {
        asc: { long: 'Status: A-Z', short: 'Status: A-Z' },
        desc: { long: 'Status: Z-A', short: 'Status: Z-A' },
      },
      sortVal: (id) => all_orgs[id].display_status,
    },
  ]

  const tableColumns = [
    {
      header: 'Support Organization Name',
      cssClass: 'col-name',
      render: (org) => (
        <a href={`/navigator_support_orgs/${org.id}`}>{org.name}</a>
      ),
      testId: 'col-org-name',
    },
    {
      header: (
        <div>
          <span>Eligibility: From</span>
          <span>Eligibility: To</span>
        </div>
      ),
      cssClass:
        'col-div_span_eligibility_from_span_span_eligibility_to_span_div',
      render: (org) => <ColEligibility org={org} bannedStates={bannedStates} />,
    },
    {
      header: 'Website',
      cssClass: 'col-website',
      render: (org) => (org.website ? <a href={org.website}>website</a> : '-'),
    },
    {
      header: 'Type',
      cssClass: 'col-type',
      render: (org) => org.org_type,
    },
    {
      header: (
        <>
          <i>Navigate</i> <span>Users</span>
        </>
      ),
      cssClass: 'col-navigate-users',
      render: (org) =>
        org.profile_ids.length ? (
          <a
            href={`/admin/navigator_support_orgs/${org.id}#tab_navigate_users`}
          >
            Y
          </a>
        ) : (
          '-'
        ),
    },
    {
      header: 'Status',
      cssClass: 'col-status',
      render: (org) => <ColStatus org={org} />,
    },
  ]

  const filterDrawers = [
    // label, URL query param, filter drawer component, props for drawer component
    {
      label: 'Support Type(s)',
      key: 'with_any_offerings',
      InputComponent: OrgArrayCheckboxInput,
      extraProps: { options: filter_drawer_options.with_any_offerings },
    },
    {
      label: 'Filter by Name',
      key: 'name_eq_any',
      InputComponent: OrgSelectInput,
      extraProps: {
        options: Object.values(all_orgs)
          .map((org) => org.name)
          .sort((a, b) =>
            a.localeCompare(b, undefined, { sensitivity: 'base' })
          ),
        isMulti: true,
      },
    },
  ]
  const recordOptionsForLinkSharingForm = useMemo(
    () => Object.values(all_orgs).map((org) => [org.name, org.id]),
    [all_orgs]
  )

  return (
    <div id="NavigatorSupportOrgIndex">
      <GenericNavigatorFacingIndex
        authenticated_api_send_to_phone_path={
          authenticated_api_send_to_phone_path
        }
        filter_drawer_options={filter_drawer_options}
        filterDrawers={filterDrawers}
        hashOfRecords={all_orgs}
        hasSelectableColumn
        initial_filters={initial_filters}
        initial_order={initial_order}
        initial_page={initial_page}
        orderItems={orderItems}
        pageTitle="Support Organizations"
        privateRecordTooltipText="This is a private organization and cannot be shared"
        recordOptionsForLinkSharing={recordOptionsForLinkSharingForm}
        recordType="NavigatorSupportOrg"
        tableColumns={tableColumns}
        topFilters={navigatorSupportOrgTopFilters}
        onStarredPage={on_starred_page}
      />
    </div>
  )
}

const OrgType = PropTypes.shape({
  private_record: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  website: PropTypes.string,
  displayStatus: PropTypes.oneOf(['Active', 'Paused']),
  rules: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number }))
    .isRequired,
})

NavigatorSupportOrgIndex.propTypes = {
  all_orgs: PropTypes.objectOf(OrgType).isRequired,
  authenticated_api_send_to_phone_path: PropTypes.string.isRequired,
  filter_drawer_options: PropTypes.shape({
    with_any_offerings: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  }).isRequired,
  initial_filters: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
  ).isRequired,
  initial_order: PropTypes.string.isRequired,
  initial_page: PropTypes.number.isRequired,
}

export default NavigatorSupportOrgIndex
