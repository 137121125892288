import usStates from '../../utilities/us_states.json'
import rubyConstants from '../../ruby_constants.js'
import {
  OrgStringInput,
  OrgSelectInput,
  OrgArrayCheckboxInput,
} from '../navigator_support_orgs/inputs/OrgInputs.jsx'
import FilterDrawerExpandableCheckboxes from './FilterDrawerExpandableCheckboxes.jsx'
import FilterDrawerAppointmentAvailability from './FilterDrawerAppointmentAvailability.jsx'
import FilterDrawerLanguageServices from './FilterDrawerLanguageServices.jsx'

const excludedStates = ['AS', 'FM', 'GU', 'MH', 'MP', 'PW', 'VI']
const usStateOptions = Object.entries(usStates)
  .filter(([x]) => x !== 'National' && !excludedStates.includes(x)) // show PR but not other territories
  .map((x) => x.reverse())

const stateRestrictionOptions = [
  ["Don't show states that require a waiting period", 'waiting_periods'],
  [
    "Don't show states that require parental involvement for minors",
    'parental_involvement',
  ],
]

const pregnancyStageOpts = Array.from({ length: 36 }, (_, i) => i + 5).map(
  (x) => [`${x} weeks`, String(x * 7)] // value must be String to match data incoming from Rails
)

export const navigatorClinicTopFilters = [
  {
    label: 'Location',
    key: 'location',
    InputComponent: OrgStringInput,
  },
  {
    label: 'Pregnancy Stage',
    key: 'pregnancy_stage_days',
    InputComponent: OrgSelectInput,
    extraProps: { options: pregnancyStageOpts },
  },
]

export const navigatorClinicFilterDrawers = (
  filterDrawerOptions,
  isUsStateDisabled
) => [
  {
    label: 'State(s)',
    key: 'state_filter_physical_or_virtual',
    InputComponent: OrgSelectInput,
    extraProps: {
      options: usStateOptions,
      isMulti: true,
      isValueDisabled: isUsStateDisabled,
    },
  },
  {
    label: 'State Restrictions',
    key: 'filter_on_state_restrictions',
    InputComponent: OrgArrayCheckboxInput,
    extraProps: { options: stateRestrictionOptions },
  },
  {
    label: 'Abortion Type',
    key: 'admin_abortion_type_filter',
    InputComponent: OrgArrayCheckboxInput,
    extraProps: {
      options: Object.entries(
        rubyConstants.NavigatorClinic.ABORTION_TYPE_FILTER
      ).map(([value, { label }]) => ({ value, label })),
    },
  },
  {
    label: 'Affiliation(s)',
    key: 'filter_on_affiliate_memberships',
    InputComponent: OrgArrayCheckboxInput,
    extraProps: {
      options: rubyConstants.NavigatorClinic.AFFILIATES_THAT_MATTER,
    },
  },
  {
    label: 'Language Services',
    key: 'filter_on_language_availability',
    InputComponent: FilterDrawerLanguageServices,
    extraProps: { options: filterDrawerOptions.languages },
  },
  {
    label: 'Appointment Availability',
    key: 'appointment_availability',
    InputComponent: FilterDrawerAppointmentAvailability,
    extraProps: {},
  },
  {
    label: 'Service Features',
    key: 'with_offerings2',
    InputComponent: OrgArrayCheckboxInput,
    extraProps: { options: filterDrawerOptions.service_features },
  },
  {
    label: 'Non-Abortion Services',
    key: 'with_offerings',
    InputComponent: FilterDrawerExpandableCheckboxes,
    extraProps: { groupedOptions: filterDrawerOptions.non_abortion_services },
  },
  {
    label: 'Abortion Appt. Days',
    key: 'abortion_days',
    InputComponent: OrgArrayCheckboxInput,
    extraProps: {
      options: [
        ['Monday', 'mon'],
        ['Tuesday', 'tue'],
        ['Wednesday', 'wed'],
        ['Thursday', 'thu'],
        ['Friday', 'fri'],
        ['Saturday', 'sat'],
        ['Sunday', 'sun'],
      ],
    },
  },
]

const UndisclosedGalValue = 0 // AKA Verified Unknown
const galSort = (value) => {
  if (typeof value !== 'number') return -1
  return value === UndisclosedGalValue ? 9999 : value
}
export const orderItems = ({
  all_clinics,
  distances,
  driveTimes,
  locationQueryLngLat,
  scopeToUsStates,
}) => {
  const isVirtual = (id) => !all_clinics[id].lat
  return [
    {
      directions: ['asc'],
      disabled: !Array.isArray(locationQueryLngLat),
      key: 'distance',
      labels: {
        asc: { long: 'Distance (default)', short: 'Distance (default)' },
      },
      sortVal: (id) => parseFloat(distances[id]) || (isVirtual(id) ? -1 : 9999),
    },
    {
      directions: ['asc'],
      disabled: !Array.isArray(locationQueryLngLat),
      key: 'drive_time',
      labels: { asc: { long: 'Shortest Drive Time', short: 'Drive Time' } },
      sortVal: (id) => driveTimes[id] || (isVirtual(id) ? -1 : 9999),
    },
    {
      directions: ['asc'],
      key: 'nearest_airport',
      labels: {
        asc: { short: 'Closest Airport', long: 'Closest to a Major Airport' },
      },
      sortVal: (id) =>
        all_clinics[id].nearby_airports?.[0]?.miles ||
        (isVirtual(id) ? -1 : 9999),
    },
    {
      directions: ['desc', 'asc'],
      key: 'abortion_pill_gal_days',
      labels: {
        asc: { long: 'Pill: Low to High', short: 'Pill: Low to High' },
        desc: { long: 'Pill: High to Low', short: 'Pill: High to Low' },
      },
      sortVal: (id) => galSort(all_clinics[id]['abortion_pill_gal_days']),
    },
    {
      directions: ['desc', 'asc'],
      key: 'abortion_procedure_gal_days',
      labels: {
        asc: { long: 'Procedure: Low to High', short: 'Procedure: Low' },
        desc: { long: 'Procedure: High to Low', short: 'Procedure: High' },
      },
      sortVal: (id) => galSort(all_clinics[id]['abortion_procedure_gal_days']),
    },
    {
      directions: ['desc', 'asc'],
      key: 'telehealth_limit',
      labels: {
        asc: { long: 'Telehealth: Low to High', short: 'Telehealth: Low' },
        desc: { long: 'Telehealth: High to Low', short: 'Telehealth: High' },
      },
      sortVal: (id) => {
        let entries = Object.entries(all_clinics[id].telehealth_limit_sort_json)
        if (scopeToUsStates.length)
          entries = entries.filter(([state]) => scopeToUsStates.includes(state))
        const values = entries.map(([, value]) => galSort(value))
        return values.length ? Math.max(...values) : 0
      },
    },
    {
      directions: ['asc', 'desc'],
      labels: {
        asc: { short: 'Provider Name: A-Z', long: 'Provider Name: A-Z' },
        desc: { short: 'Provider Name: Z-A', long: 'Provider Name: Z-A' },
      },
      key: 'name',
      sortVal: (id) => all_clinics[id].formatted_name,
    },
  ]
}
