import React, { useState, useEffect, useRef, useMemo } from 'react'
import lodash from 'lodash'

import FilterDrawerWrapper from './FilterDrawerWrapper.jsx'
import filterIcon from '../../../assets/images/filter_icon.svg'
import './FilterDrawers.scss'

const throttle = (func, wait) => {
  let timeout = null
  return (...args) => {
    if (!timeout) {
      timeout = setTimeout(() => {
        func.apply(this, args)
        timeout = null
      }, wait)
    }
  }
}

const processFilterValue = (key, value) => {
  // if the value is falsey or an empty array, remove the key from the object
  if (!value || (Array.isArray(value) && !value.some((x) => x)))
    return (prev) =>
      Object.fromEntries(Object.entries(prev).filter((x) => x[0] !== key))
  // otherwise set to the new value
  return (prev) => ({ ...prev, [key]: value })
}

const FilterDrawers = ({
  applyFilters,
  drawers,
  filterQueryParams,
  hideSection = false,
}) => {
  const [openDrawer, setOpenDrawer] = useState(null)
  const [pendingFilterChange, setPendingFilterChange] =
    useState(filterQueryParams)
  const [isExpanded, setIsExpanded] = useState(false)

  const handleApply = () => {
    if (filterQueryParams === pendingFilterChange) return
    applyFilters(pendingFilterChange)
    setOpenDrawer(null)
  }

  const handleClear = (filterKey) => {
    // reset to currently selected filters, minus this one
    setPendingFilterChange(
      Object.fromEntries(
        Object.entries(filterQueryParams).filter((x) => x[0] !== filterKey)
      )
    )
  }

  const handleReset = (alsoCloseDrawer) => {
    setPendingFilterChange(filterQueryParams) // reset to currently selected filters
    if (alsoCloseDrawer) setOpenDrawer(null)
  }

  useEffect(() => {
    setPendingFilterChange(filterQueryParams)
  }, [filterQueryParams]) // when filters are cleared, we need to reset the drawers

  // START filter drawer expand/collapse
  const containerRef = useRef(null)
  // start with all drawers visible in order to calculate their widths
  const [visibleDrawers, setVisibleDrawers] = useState(99)
  const initialDrawerLoadingClassName = visibleDrawers === 99 ? 'loading' : ''
  const drawerWidths = useMemo(
    () => {
      if (!containerRef.current) return []
      return Array.from(
        containerRef.current.querySelectorAll('.drawer-handle')
      ).map((drawer) => drawer.getBoundingClientRect().width)
    },
    [containerRef.current] // Recalculate after the ref is attached to the container
  )

  // either we have more than 4 drawers OR if we only have a few but the window is small enough to hide them
  const showExpandDrawersButton =
    drawers.length >= 4 || visibleDrawers < drawers.length

  const toggleDrawersOnContainerSize = () => {
    const drawerContainer = containerRef.current
    if (!drawerContainer || !drawerWidths || !drawerWidths.length) return

    const containerWidth = drawerContainer.getBoundingClientRect().width
    let accumulatedWidth = 0
    let countVisibleDrawers = 0
    const drawerHandleSpacing = 15 // must match .filters-flex-container column-gap in css

    for (let index = 0; index < drawers.length; index += 1) {
      const drawerWidth = drawerWidths[index] + drawerHandleSpacing
      if (accumulatedWidth + drawerWidth > containerWidth) break

      accumulatedWidth += drawerWidth
      countVisibleDrawers += 1
    }
    setVisibleDrawers(Math.max(countVisibleDrawers, 1))
  }

  useEffect(() => {
    // run this on initial page load, and again a couple times after things are rendered
    setTimeout(toggleDrawersOnContainerSize, 25)
    setTimeout(toggleDrawersOnContainerSize, 100)
    setTimeout(toggleDrawersOnContainerSize, 500)

    const throttledToggle = throttle(toggleDrawersOnContainerSize, 100) // 100ms throttle
    window.addEventListener('resize', throttledToggle)
    return () => window.removeEventListener('resize', throttledToggle)
  }, [toggleDrawersOnContainerSize, containerRef.current])

  // END filter drawer expand/collapse

  const renderDrawer = ({ label, key, InputComponent, extraProps }, index) => {
    const stylingClassName = lodash.kebabCase(label)
    const activatedClassName =
      pendingFilterChange[key] !== filterQueryParams[key] ||
      (pendingFilterChange[key] && pendingFilterChange[key] !== 'default')
        ? 'activated'
        : 'default'

    return (
      <FilterDrawerWrapper
        key={key}
        isOpen={openDrawer === label}
        onOpen={() => setOpenDrawer(label)}
        onClear={() => handleClear(key)}
        onReset={(bool) => handleReset(bool)}
        onApply={handleApply}
        title={label}
        className={`${stylingClassName} ${activatedClassName} drawer-${index} ${initialDrawerLoadingClassName}`}
      >
        <InputComponent
          value={
            (openDrawer === label ? pendingFilterChange : filterQueryParams)[
              key
            ]
          }
          onChange={(newValue) =>
            setPendingFilterChange(processFilterValue(key, newValue))
          }
          {...extraProps}
        />
      </FilterDrawerWrapper>
    )
  }

  return (
    <div className="FilterDrawers">
      {!hideSection && (
        <div className="filter-drawers-title-with-icon">
          Filters
          <img src={filterIcon} alt="filter icon" />
        </div>
      )}
      <div
        className={`filters-flex-container ${isExpanded ? '' : 'closed'}`}
        ref={containerRef}
      >
        {drawers
          .slice(0, isExpanded ? 99 : visibleDrawers)
          .map((drawer, index) => renderDrawer(drawer, index))}
      </div>

      {showExpandDrawersButton && (
        <button
          className="show-filters"
          type="button"
          onClick={() => setIsExpanded((prev) => !prev)}
        >
          {isExpanded ? 'Show fewer filters -' : 'Show all filters +'}
        </button>
      )}
    </div>
  )
}

export default FilterDrawers
