const deepmerge = require('deepmerge') // eslint-disable-line import/no-extraneous-dependencies
const _isObject = require('lodash/isObject')

export function deepMerge(target, incoming) {
  // deep merge objects. don't merge arrays.
  return deepmerge(target, incoming, {
    arrayMerge: (targetArray, incomingArray, options) => incomingArray, // eslint-disable-line no-unused-vars
  })
}

const removeEmpty = (obj) => {
  if (Array.isArray(obj) || !_isObject(obj)) return obj

  const cleaned = Object.entries(obj).reduce((acc, [key, value]) => {
    if (
      value === '' ||
      value === null ||
      value === undefined ||
      (_isObject(value) && Object.keys(value).length === 0)
    )
      return acc
    const cleanedValue = removeEmpty(value)
    if (_isObject(cleanedValue) && Object.keys(cleanedValue).length === 0)
      return acc
    return { ...acc, [key]: cleanedValue }
  }, {})

  // Recursively remove empty objects
  for (const key in cleaned) {
    if (_isObject(cleaned[key])) {
      const nestedCleaned = removeEmpty(cleaned[key])
      if (Object.keys(nestedCleaned).length === 0) delete cleaned[key]
      else cleaned[key] = nestedCleaned
    }
  }

  return cleaned
}

export const mergeAndClean = (obj1, obj2) => {
  const merged = deepMerge(obj1, obj2)
  return removeEmpty(merged)
}
