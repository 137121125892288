export const urlAddHttps = (url, updateFnc) => {
  if (url && !/^https?:\/\//.test(url)) {
    const newUrl = `https://${url}`
    updateFnc(newUrl)
  }
}

export const getCsrfToken = () => {
  const csrfElement = document.querySelector("meta[name='csrf-token']")
  if (csrfElement) return csrfElement.getAttribute('content')
  if (typeof jest === 'object') return null // expected to not find csrf tag in test environment
  return console.warn('CSRF token meta tag not found!')
}

export const calculateBottomOfHeader = () => {
  const header = document.getElementById('root-sticky-top-navlinks')
  if (!header) return console.error('Could not find header element')
  return header.offsetTop + header.offsetHeight
}

// like ruby's partition, splits an array into two arrays
// based on the return value of the predicate
export const partition = (arr, predicate) => {
  const [truthy, falsy] = [[], []]
  arr.forEach((item) => (predicate(item) ? truthy : falsy).push(item))
  return [truthy, falsy]
}
